import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows technical info
 */
export default function Databases() {
	
	return (<>
		<Helmet>
			<title>Software Engineer Portfolio | MySQL | Postgres | MS SQL Server | Oracle | DB2 | Solr | San Diego</title>
			<meta name="description" content={`I am a software engineer/architect in San Diego.
					In this section you can see the databases I used. I am pretty much an expert in SQL.`} />
		</Helmet>

		<h1>Databases</h1>

		<ul>
			<li>MySQL</li>
			<li>Postgres</li>
			<li>Oracle</li>
			<li>MS SQL Server</li>
			<li>DB2</li>
			<li>Solr</li>
			<li>Hbase</li>
			<li>Memcached</li>
			<li>MongoDB</li>
			<li>Analysis Server</li>
		</ul>
	</>);
}