import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Odsaz() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | C++ | Delphi | pSOS | Interbase | Istanbul</title>
			<meta name="description" content={`I worked on an accounting system written in Delphi (GUI enabled Turbo Pascal).
			It used Interbase as database. I also worked on ATM network router driver for pSOS using emulated OS layers.`} />
		</Helmet>

		<h1>Odsaz</h1>
		
		<p>This was a small company in Turkey with big goals.</p>
			
		<p>
			Company had partnered with Integrated Systems in San Diego to build pSOS drivers for an ATM Network router.
			I worked on that project for about a month. It was interesting. Since we did not have the actual pSOS but we had documentation for it, the previous developer
			had written an emulation layer in DOS with C. He had emulated threads. Started converting that to Linux to use POSIX threads but before I could complete it the
			contract was cancelled.
		</p>
		
		<p>
			Then I started working on a Delphi version of their accounting/ERP system. I converted the accounting module and made good progress on a good deal of modules.
		</p>
		
		<p>
			<span className="tech">Technologies Used:</span>
			C/C++, Delphi, pSOS, Interbase.
		</p>
	</>);
}