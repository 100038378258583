import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishments main text
 */
export default function Summary() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | Technical Knowledge | Servers | OS | Programming Languages | Databases | San Diego</title>
			<meta name="description" content={'You can see my technical knowledge in this section in programming languages, databases, operating systems and servers/services.'} />
		</Helmet>

		<h1>Technical Knowledge</h1>
		
		<p>You can see a list of technical knowledge based on different categories on the left navigation. Please click on each to learn about them.</p>
	</>);
}