import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LinkButton from '../shared/linkButton';

import './nav.css';

/**
 * menu
 */
export default function Nav() {
	
	return (
		<div className="nav">
			<AppBar color="primary" position="static">
				<Toolbar>
					<LinkButton href="/">Home</LinkButton>
					<LinkButton href="/portfolio">Projects</LinkButton>
					<LinkButton href="/technical">Technical</LinkButton>
					<LinkButton href="/education">Education</LinkButton>
					<LinkButton href="/contact">Contact</LinkButton>
				</Toolbar>
			</AppBar>
		</div>
	);
}