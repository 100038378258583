import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows technical info
 */
export default function Servers() {
	
	return (<>
		<Helmet>
			<title>Software Engineer Portfolio | Nginx | Magento | Jenkins | Netsuite | AWS | Azure | San Diego</title>
			<meta name="description" content={`I am a software engineer/architect in San Diego.
					In this section you can see the systems I used.`} />
		</Helmet>

		<h1>Servers, Systems and Services</h1>

		<ul>
			<li>
				Web Servers
				<ul>
					<li>Apache</li>
					<li>Nginx</li>
					<li>IIS</li>
				</ul>
			</li>
			<li>
				Email
				<ul>
					<li>Qmail</li>
					<li>Postfix</li>
					<li>Courier</li>
				</ul>
			</li>
			<li>
				Services
				<ul>
					<li>Magento</li>
					<li>Netsuite</li>
					<li>Salesforce</li>
					<li>Azure</li>
					<li>AWS</li>
				</ul>
			</li>
			<li>
				Devops
				<ul>
					<li>Jenkins</li>
				</ul>
			</li>
			<li>
				ETL
				<ul>
					<li>SSIS</li>
				</ul>
			</li>
			<li>
				File Systems
				<ul>
					<li>Lustre</li>
				</ul>
			</li>
		</ul>
	</>);
}