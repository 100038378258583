import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows technical info
 */
export default function OS() {
	
	return (<>
		<Helmet>
			<title>Software Engineer Portfolio | Linux | Windows | Solaris | AIX | OS/390 | San Diego</title>
			<meta name="description" content={`I am a software engineer/architect in San Diego.
					In this section you can see the operating systems I used. I like Linux for production and Windows for development.`} />
		</Helmet>

		<h1>Operating Systems</h1>

		<ul>
			<li>Linux</li>
			<li>Windows</li>
			<li>FreeBSD</li>
			<li>Solaris</li>
			<li>OS/390</li>
			<li>AIX</li>
		</ul>
	</>);
}