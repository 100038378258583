import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import './App.css';

import Header from './header/header';
import Nav from './nav/nav';
import Footer from './footer/footer';
import Home from './pages/home/home';
import Portfolio from './pages/portfolio/portfolio';
import Education from './pages/education/education';
import Technical from './pages/technical/technical';
import Contact from './pages/contact/contact';

/**
 * main app
 */
function App() {
	
	const theme = createMuiTheme({
		palette: {
			type: 'light',
			primary1Color: '#03a9f4',
			primary2Color: '#0277bd',
			primary3Color: '#01579b',
			accent1Color: '#ff7043',
			accent2Color: '#f4511e',
			accent3Color: '#bf360c',
			alternateTextColor: 'rgba(0, 0, 0, 0.68)',
			canvasColor: 'grey'
		}
	});
	
	return (
		<MuiThemeProvider theme={theme}>
			<div className="content">
				
				<Helmet>
					<title>Software Engineer | React | Node | Full Stack | MySQL | Machine Learning | San Diego</title>
					<meta name="description" content={`I am a software engineer/architect in San Diego.
					This site is built to show case my accomplishments using technologies such as React, Angular, Node and MySQL. Focus on Full Stack development and Machine Learning.`} />
				</Helmet>
				
				<CssBaseline />
				
				<Router>
					<Header />
					<Nav />
					<div className="page">
						<Route exact path="/" component={Home} />
						<Route path="/portfolio" component={Portfolio} />
						<Route path="/technical" component={Technical} />
						<Route path="/education" component={Education} />
						<Route path="/contact" component={Contact} />
					</div>
				</Router>
				
				<Footer />
			</div>
		</MuiThemeProvider>
	);
}

export default App;
