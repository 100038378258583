import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import './footer.css';

/**
 * footer
 */
export default function Footer() {
	return (
		<div className="footer">
			<AppBar color="primary" position="static">
				<Toolbar>
					<div className="copyright">
						<a href="https://www.linkedin.com/in/cagdasucar" target="_new">Cagdas Ucar</a>
					</div>
				</Toolbar>
			</AppBar>
		</div>
	);
}