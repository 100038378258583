import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Garanti() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | PL/1 | ISO 8583 | CICS | OS/390 | AIX | DB2 | Istanbul</title>
			<meta name="description" content={`I re-wrote the credit card server for Garanti Bank on OS/390 mainframes.
			The server ran on CICS. Program written in PL/1 and DB2. Supporting functionality in AIX.`} />
		</Helmet>

		<h1>Garanti Technology</h1>
		
		<p>
			Technology infrastructure provider for Garanti Bank, one of Turkey’s largest banks and its affiliates.
			Here is the link for the company website: <a href="https://www.garantiteknoloji.com.tr/homepage.aspx" target="_new">Garanti Teknoloji</a>
		</p>
		
		<h2>Accomplishments</h2>
		
		<ul>
			<li>
				<span className="feature">Credit Card Server.</span>
				Re-factored ISO 8583 electronic payment server (on CICS with PL/1) and completed VSAM to DB2 migration. Improved peak day performance from 7-8 seconds to below 1 second.
				Reached record transaction volume.
			</li>
			<li>
				<span className="feature">Temporary Credit Cards.</span>
				Developed web application to provide temporary cards to customers who apply for store credit cards. Result: increased transactions by more than $50,000 per month.
			</li>
			<li>
				<span className="feature">Recurring Billing.</span>
				Fixed all bugs of in-house recurring billing solution given to member merchants and re-factored code to improve performance.
				Reached record volume of more than 100,000 transactions per month.
			</li>
		</ul>
		
		<p>
			<span className="tech">Technologies Used:</span>
			PL/1, C, Visual Basic 6, JCL, TSO, CICS, Apache, DB2/390 v6, OS/390, AIX.
		</p>
	</>);
}