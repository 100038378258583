import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Carsinia() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | LAMP Stack | Linux | PHP | MySQL | DMS | San Diego</title>
			<meta name="description" content={'I built a Dealer Management System for used car dealers with Apache, PHP, MySQL and Linux (LAMP Stack).'} />
		</Helmet>

		<h1>Carsinia</h1>
		
		<p>
			A friend of mine asked for a system to manage his reconditioning process. It evolved into a full used car dealer management system.
			Here is the link for it: <a href="https://carsinia.com" target="_new">https://carsinia.com</a>
		</p>
			
		<p>
			<span className="tech">Technologies Used:</span>
			LAMP stack (Linux, Apache, MySQL, PHP), JavaScript.
		</p>
	</>);
}