import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Route, Link } from 'react-router-dom';

import Summary from './summary';
import Languages from './languages';
import Databases from './databases';
import Servers from './servers';
import OS from './os';

/**
 * shows technical knowledge
 */
export default function Technical() {
	
	return (<>
		<div className="side-nav">
			<List>
				<ListItem><Link to="/technical/languages">Languages</Link></ListItem>
				<ListItem><Link to="/technical/databases">Databases</Link></ListItem>
				<ListItem><Link to="/technical/systems">Servers</Link></ListItem>
				<ListItem><Link to="/technical/os">Operating Systems</Link></ListItem>
			</List>
		</div>
		
		<div className="page-content">
			<Route path="/technical" exact component={Summary} />
			<Route path="/technical/languages" exact component={Languages} />
			<Route path="/technical/databases" exact component={Databases} />
			<Route path="/technical/systems" exact component={Servers} />
			<Route path="/technical/os" exact component={OS} />
		</div>
	</>);
}