import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Exametric() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | SSIS | DTS | C# | SQL Server | San Diego</title>
			<meta name="description" content={`I worked on forecasting software for banks such as Bank of America and Wells Fargo.
			Used linear regression, sinusoidal fitting with fourier discrete transform, OLAP and MDX, optimized SQL stored procedures.`} />
		</Helmet>

		<h1>Exametric</h1>
		
		<p>
			Forecasting and planning software provider to organizations such as Bank of America and Wells Fargo.
			The company was acquired by Witness Systems and then Verint.
			Here is the link for the company website: <a href="https://www.verint.com" target="_new">https://www.verint.com</a>
		</p>
		
		<h2>Accomplishments</h2>
		
		<ul>
			<li>
				<span className="feature">Forecast Accuracy.</span>
				Analyzed and tested 20+ forecast algorithms for research and development.
				Innovated new features for optimizing forecast algorithm hyper parameters and analyzed data patterns to increase forecast accuracy.
				Secured $2 million international contract by surpassing rivals in forecast contest with over 40% improved accuracy.
			</li>
			<li>
				<span className="feature">Import speed.</span>
				Optimized SQL queries and DTS packages. Results: data import speed increased 5 times and forecast performance improved by more than 100%.
			</li>
			<li>
				<span className="feature">OLAP and Data Mining.</span>
				Introduced OLAP data warehouse component. Result: enabled future expansions such as new algorithms, reports and data mining operations.
			</li>
		</ul>
		
		<p>
			<span className="tech">Technologies Used:</span>
			VB6, C#, IIS, OWC, MS SQL and Analysis (OLAP) Server 2000, DTS, MDX.
		</p>
	</>);
}