import React from 'react';
import { Helmet } from 'react-helmet';

import './home.css';
import LinkButton from '../../shared/linkButton';
import cagdasPicture from './cagdas.png';

/**
 * shows home page
 */
export default function Home() {

	return (
		<div className="home">
			<Helmet>
				<title>Software Engineer | Performance Optimization | Business Systems | Data Analysis | Machine Learning | San Diego</title>
				<meta name="description" content={`Cagdas Ucar is a software engineer in San Diego, specializing in business systems, performance optimization,
				data analysis and machine learning.`} />
			</Helmet>

			<img src={cagdasPicture} alt="Cagdas Ucar" />
			
			<h1>Cagdas Ucar</h1>
			
			<p>Welcome. This site is created to showcase some of my work and accomplishments. Let me start by explaining how my name is pronounced.
				It is a Turkish name and the pronunciation is quite different than it looks. The Cs in my name are actually a different Turkish letter with &quot;ch&quot; sound.
				Similar for the s, which sounds like &quot;sh&quot;. Finally, the g is sort of a silent g, like a gh sound. So my name is pronounced like &quot;Cha-dosh&quot;.</p>
			
			<p>I have been a software engineer for about 20 years and I love my profession. I enjoy coding as well as software design. I have used many languages and technologies
				along the way. Node, Angular, React, PHP, Perl, C#, C/C++, Java, Scala are some of the prominent ones. I love designing big systems, software architecture and performance optimizations.
				This site lists some of my accomplishments in those areas.</p>
			
			<LinkButton variant="contained" color="primary" href="/portfolio">Portfolio</LinkButton>
			<br/><br/>
		</div>
	);
}