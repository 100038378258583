import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows turing info
 */
export default function Turing() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | React | JavaScript | MySQL | Linux | Node.js | Koa | San Diego</title>
			<meta name="description" content={`There is a company called Turing that requires you to build a shopping cart application as part of their application process.
			A challenge. I built one with React, Koa and MySQL.`} />
		</Helmet>

		<h1>Turing Challenge</h1>
		
		<p>
			There is a company called Turing that requires you to build a shopping cart application as part of their application process. A challenge.
			I built one with React, Koa and MySQL. Check it out:
		</p>
		<p>
			<a href="https://turing.cagdasucar.com" target="_new">https://turing.cagdasucar.com</a>
		</p>
	</>);
}