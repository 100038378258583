import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishments main text
 */
export default function Summary() {
	
	return (<>
		<Helmet>
			<title>Software Engineer Portfolio | React | Node | Full Stack | MySQL | Machine Learning | San Diego</title>
			<meta name="description" content={`I am a software engineer/architect in San Diego.
					In this section you can see my accomplishments using technologies such as React, Angular, Node and MySQL. Focus on Full Stack development and Machine Learning.`} />
		</Helmet>
		
		<h1>Portfolio</h1>
		
		<p>You can see a list of accomplishments on the left navigation. Please click on each to learn about them.</p>
	</>);
}