import React from 'react';
import { Helmet } from 'react-helmet';

import './languages.css';

/**
 * shows technical info
 */
export default function Languages() {
	
	return (<>
		<Helmet>
			<title>Software Engineer Portfolio | React | Angular | PHP | MySQL | C# | C/C++ | Scala | Spark | San Diego</title>
			<meta name="description" content={`I am a software engineer/architect in San Diego.
					In this section you can see the programming languages I used.`} />
		</Helmet>

		<h1>Languages</h1>
		
		<ul>
			<li>
				<span className="language">JavaScript / Typescript:</span>
				<ul>
					<li>React: This site is built with React. Also <a href="https://turing.cagdasucar.com" target="_new">Turing Challenge</a>.</li>
					<li>Angular: Not to be confused with AngularJS. I use Angular 2+ with Typescript.</li>
					<li>Material: Both Angular and React. This site uses Material Design.</li>
					<li>Webstorm IDE: I can use VSCode as well but I like Webstorm better.</li>
					<li>NodeJS</li>
					<li>Express: Typescript and JavaScript.</li>
					<li>Koa</li>
					<li>Mocha</li>
					<li>Chai</li>
					<li>Sinon</li>
					<li>Protractor</li>
				</ul>
			</li>
			<li>
				<span className="language">PHP:</span> I prefer vanilla PHP but can use frameworks if needed.
				<ul>
					<li>Composer</li>
					<li>Zen</li>
				</ul>
			</li>
			<li>
				<span className="language">C#:</span>
				<ul>
					<li>ASP.NET</li>
					<li>.NET 4.5</li>
					<li>.NET Core</li>
					<li>VSCode / Visual Studio</li>
				</ul>
			</li>
			<li>
				<span className="language">C/C++:</span>
				<ul>
					<li>Windows Visual Studio</li>
					<li>Linux gcc/Makefile</li>
				</ul>
			</li>
			<li>
				<span className="language">Java:</span>
				<ul>
					<li>Spark</li>
					<li>Spring API</li>
				</ul>
			</li>
			<li>
				<span className="language">Scala:</span>
				<ul>
					<li>Spark</li>
					<li>Spark MLLib</li>
				</ul>
			</li>
			<li><span className="language">Perl</span></li>
			<li><span className="language">R</span> (Analytics and Machine Learning)</li>
			<li><span className="language">MDX</span> (OLAP - Microsoft Analysis Server)</li>
			<li><span className="language">SQL</span> (MySQL, MSSQL, Postgres, Oracle, Sybase, Interbase)</li>
		</ul>
	</>);
}