import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { Route, Link } from 'react-router-dom';

import './portfolio.css';

import Summary from './summary';
import Turing from './turing';
import Abacus from './abacus';
import WeedGuide from './weedguide';
import OrderPigeon from './orderpigeon';
import InternetMatrix from './imatrix';
import Brickfish from './brickfish';
import HSBC from './hsbc';
import Miva from './miva';
import Exametric from './exametric';
import Garanti from './garanti';
import Odsaz from './odsaz';
import Carsinia from './carsinia';
import MySqlMail from './mysqlmail';
import Factorization from './factorization';

/**
 * shows portfolio
 */
export default function Portfolio() {
	
	return (<>
		<div className="side-nav">
			<List>
				<ListItem><Link to="/portfolio/abacus">Abacus</Link></ListItem>
				<ListItem><Link to="/portfolio/orderpigeon">OrderPigeon</Link></ListItem>
				<ListItem><Link to="/portfolio/imatrix">iMatrix</Link></ListItem>
				<ListItem><Link to="/portfolio/brickfish">Brickfish</Link></ListItem>
				<ListItem><Link to="/portfolio/hsbc">HSBC</Link></ListItem>
				<ListItem><Link to="/portfolio/miva">Miva</Link></ListItem>
				<ListItem><Link to="/portfolio/exametric">Exametric</Link></ListItem>
				<ListItem><Link to="/portfolio/garanti">Garanti</Link></ListItem>
				<ListItem><Link to="/portfolio/odsaz">Odsaz</Link></ListItem>
			</List>
			<Divider />
			<List>
				<h3>Side Projects</h3>
				<ListItem><Link to="/portfolio/turing">Turing</Link></ListItem>
				<ListItem><Link to="/portfolio/weedguide">Weedguide</Link></ListItem>
				<ListItem><Link to="/portfolio/carsinia">Carsinia</Link></ListItem>
				<ListItem><Link to="/portfolio/mysqlmail">MySQL Mail</Link></ListItem>
				<ListItem><Link to="/portfolio/factorization">Factorization</Link></ListItem>
			</List>
		</div>
		
		<div className="page-content">
			<Route path="/portfolio" exact component={Summary} />
			<Route path="/portfolio/abacus" exact component={Abacus} />
			<Route path="/portfolio/orderpigeon" exact component={OrderPigeon} />
			<Route path="/portfolio/imatrix" exact component={InternetMatrix} />
			<Route path="/portfolio/brickfish" exact component={Brickfish} />
			<Route path="/portfolio/hsbc" exact component={HSBC} />
			<Route path="/portfolio/miva" exact component={Miva} />
			<Route path="/portfolio/exametric" exact component={Exametric} />
			<Route path="/portfolio/garanti" exact component={Garanti} />
			<Route path="/portfolio/odsaz" exact component={Odsaz} />
			<Route path="/portfolio/turing" exact component={Turing} />
			<Route path="/portfolio/weedguide" exact component={WeedGuide} />
			<Route path="/portfolio/carsinia" exact component={Carsinia} />
			<Route path="/portfolio/mysqlmail" exact component={MySqlMail} />
			<Route path="/portfolio/factorization" exact component={Factorization} />
		</div>
	</>);
}