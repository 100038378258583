import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function HSBC() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | Perl | MySQL | Business Intelligence | Data Analysis | CRM | Data Mart | San Diego</title>
			<meta name="description" content={`I wrote a CRM in Perl and MySQL for HSBC auto finance used to store segmentation and communication for dealers.
			I also created a number of reports for business intelligence and analyzed data.`} />
		</Helmet>

		<h1>HSBC Auto Finance</h1>
		
		<p>
			Auto finance division of HSBC, one of the largest financial services organizations in the world.
			Here is the link for the company website: <a href="https://hsbc.com" target="_new">https://hsbc.com</a>
		</p>
		
		<h2>Accomplishments</h2>
		
		<ul>
			<li>
				<span className="feature">Segmentation &amp; CRM.</span>
				Created dealer channel CRM system to execute market segmentation and resource planning strategies. Provided Blackberry interface for sales force.
				Completed data migration from Salesforce and older systems. Reached active employee user base of 400+. Over 13,000 dealer contacts were collected and segmented.
				More than 15,000 calls and 70,000 visits were recorded.
			</li>
			<li>
				<span className="feature">Business Intelligence.</span>
				Created a mini data mart and created/enhanced 20+ business intelligence reports for effective performance management and process optimization.
				Introduced competitive intelligence portal. Consolidated data from multiple marketing research sources and built a framework for analyzing market trends and product segments.
				Determined most significant variables for new market pricing model.
			</li>
		</ul>
		
		<p>
			<span className="tech">Technologies Used:</span>
			Perl, JavaScript, AJAX, OWC, VBA, C#, SAS, R, Apache, Excel, Oracle 10g, DB2/400, MySQL, Solaris, AS/400, OS/390, TSO, ISPF, Windows, Lotus Notes.
		</p>
	</>);
}