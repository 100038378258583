import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

/**
 * link button
 */
function LinkButton(props) {
	
	const linkStyle = { textDecorationLine: 'none', color: 'white' };
	
	return (
		<Button variant={props.variant} color={props.color}>
			<Link style={linkStyle} to={props.href}>{props.children}</Link>
		</Button>
	);
}

LinkButton.propTypes = {
	variant: PropTypes.string,
	color: PropTypes.string,
	href: PropTypes.string.isRequired,
	children: PropTypes.node
};

export default LinkButton;