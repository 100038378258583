import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function MySqlMail() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | C++ | MySQL | POSIX Threads | BSD Sockets | San Diego</title>
			<meta name="description" content={`I created an SMTP mail server in C++. It was multi-threaded and it ran on all operating systems (Linux, Windows, Mac).
			It used MySQL to store incoming emails.`} />
		</Helmet>

		<h1>MySQL Mail Server</h1>
		
		<p>
			I just could not understand why mail servers use file system for mail storage. I wanted to build a mail server that uses MySQL for storage.
			I completed the SMTP incoming layer. It was written in C++. It was cross platform and was using threads. I cannot find the source code but I am pretty sure it is
			in one of the old drives. I will put it up on github one of these days.
		</p>
			
		<p>
			<span className="tech">Technologies Used:</span>
			C++, MySQL.
		</p>
	</>);
}