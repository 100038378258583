import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import './header.css';

/**
 * header
 */
export default function Header() {

	return (
		<div className="header">
			<Toolbar>
				<div className="logo">
					Cagdas Ucar
				</div>
				<div className="bullets">
					<span className="engineering">Software Engineering</span><br/>
					<div className="expertise">
						Systems Architecture <br/>
						Full Stack Development <br/>
						Big Data & Machine Learning
					</div>
				</div>
			</Toolbar>
		</div>
	);
}