import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows education
 */
export default function Education() {
	return (<>
		<Helmet>
			<title>Software Engineer | Bogazici University | Bosphorus University | Machine Learning | Bioinformatics | San Diego</title>
			<meta name="description" content={`I finished computer engineering program at Bosphorus University. I enhanced my knowledge with additional courses.
			I learned about Visa, CICS, DB2, Neural Networks, Machine Learning and Bioinformatics. I always like to study math.`} />
		</Helmet>

		<div className="page-content">
			<h1>Education</h1>
			
			<p>It is worth noting that I love math. I was 16th in Math Olympics prep team in Turkey. That is why I like Machine Learning and Bioinformatics.</p>
			
			<h2>College</h2>
			
			<p>Bachelor of Science, Computer Engineering, Bogazici University, Istanbul, Turkey</p>
			
			<p>
				My graduation project was a Parallel Database written in C++. I am still pretty proud of it. It had a shared nothing architecture. I will upload the source
				to github one of these days.
			</p>
			
			<h2>Certifications</h2>
			
			<ul>
				<li>Genome Sequencing (Bioinformatics II), UCSD (Coursera) - could not complete it due to a technical issue on the system but I finished the program.</li>
				<li>Finding Hidden Messages in DNA (Bioinformatics I), UCSD (Coursera)</li>
				<li>Machine Learning, Stanford University (Coursera)</li>
				<li>Neural Networks and Deep Learning, Andrew Ng (Coursera)</li>
				<li>Basic Banking Education, Garanti Bank</li>
				<li>VISA Base II Education, VISA Turkey</li>
				<li>CICS Application Programming, IBM Turkey</li>
				<li>DB2 Application Programming, IBM Turkey</li>
			</ul>
		</div>
	</>);
}