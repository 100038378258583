import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows contact info
 */
export default function Contact() {
	return (
		<div className="page-content">
			<Helmet>
				<title>Software Engineer | LinkedIn | Performance Optimization | Business Systems | Data Analysis | Machine Learning | San Diego</title>
				<meta name="description" content={`Cagdas Ucar is a software engineer in San Diego, specializing in business systems, performance optimization,
				data analysis and machine learning. Use LinkedIn to contact.`} />
			</Helmet>

			<h1>Contact</h1>
			
			<p>If you would like to get in touch, please use LinkedIn. Here is my profile: <a href="https://www.linkedin.com/in/cagdasucar" target="_new">Cagdas Ucar</a>.</p>
		</div>
	);
}