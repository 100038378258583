import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function InternetMatrix() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | PHP | MySQL | Linux | Apache | LAMP | JavaScript | San Diego</title>
			<meta name="description" content={`I created a load balanced cluster for serving websites with LAMP stack (Linux, Apache, MySQL and PHP).
			I also created a completely automated ordering system that can generate websites. Designed and implemented a newsletter mailer.`} />
		</Helmet>

		<h1>iMatrix</h1>
		
		<p>
			Leading website provider for chiropractors and vets. Re-designed and created the entire system that administers and serves websites.
			Here is the link for it: <a href="https://imatrix.com" target="_new">https://imatrix.com</a>
		</p>
		
		<h2>Accomplishments</h2>
	
		<ul>
			<li>
				<span className="feature">Website cluster system.</span>
				Consolidated company servers and code base. Designed and created a cluster system with load balanced web, email and DNS services, MySQL and Memcached backend and
				Lustre parallel file system as SAN. Results: 10,000+ websites in a single framework. Improved reliability, capacity and reduced costs.
			</li>
			<li>
				<span className="feature">E-commerce store.</span>
				Designed and created e-commerce store and billing system that can provision websites and domains.
				Oversaw implementation as well as coding with engineers. Results: efficient sales, increased revenue.
			</li>
			<li>
				<span className="feature">Newsletter/card mailer.</span>
				Created mailer system for newsletters with parallel sending, velocity and acceleration checks that worked on multiple IPs.
				Results: improved delivery rates (150,000 emails/day) and company IP reputation.
			</li>
			<li>
				<span className="feature">Webmail.</span>
				Intergated Horde and atMail systems with company admin interface. Customized Spam Assassin client written in C.
			</li>
		</ul>
	
		<p>
			<span className="tech">Technologies Used:</span>
			PHP, C/C++, JavaScript, MySQL, Memcached, Qmail, TinyDNS, Courier, Lustre.
		</p>
	</>);
}