import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function WeedGuide() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | Scala | Spark | MySQL | Linux | React | Node.js | San Diego</title>
			<meta name="description" content={`I created a distributed web crawler with Spark and Scala. It used MySQL as storage. I also created a machine learning model to categorize
			web pages. I used Neural Networks to do that. I also tested SVM, Naive Bayes, Decision Trees and Random Forests. I re-implemented the API with R and Java Spring.`} />
		</Helmet>

		<h1>Weedguide</h1>
		
		<p>
			Search engine specific for cannabis. Contract work for a friend.
			Here is the link for it: <a href="https://weedguide.com" target="_new">https://weedguide.com</a>
		</p>
		
		<h2>Accomplishments</h2>
	
		<ul>
			<li>
				<span className="feature">Spark distributed crawler.</span>
				Created distributed web crawler to fetch pages and save to MySQL. Can use plain fetch or Chrome for better processing. It can scale up to 4,000 nodes.
			</li>
			<li>
				<span className="feature">Web page classification.</span>
				Built machine learning models to classify web content. Used spark MLlib and then later switched to R. Made the classification available in a Spring API.
				Created training data collection UI in React application.
			</li>
		</ul>
	
		<p>
			<span className="tech">Technologies Used:</span>
			Java, Spring, Scala, Spark, MLLib, R, MySQL, Hbase, React, NodeJS, Express, Solr.
		</p>
	</>);
}