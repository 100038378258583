import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Factorization() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | Integer Factorization | PHP | Linear Algebra | San Diego</title>
			<meta name="description" content={`I wrote a program in PHP that can solve binary equations to determine the integer factors of a number.
			It needs to be improved with BDDs and/or p-adic numbers.`} />
		</Helmet>

		<h1>Integer Factorization</h1>
		
		<p>
			Integer factorization has long been an obsession of mine. I have been trying to come up with an algorithm that would factorize big integer in logarithmic time complexity.
			I came up with a pretty good solver a while back and put it up on github. Feel free to check out the source code:
			<a href="https://github.com/cucar/factorization">https://github.com/cucar/factorization</a>
		</p>
		
		<p>
			I think the algorithm needs to be improved using BDD (Binary Decision Diagrams) and/or p-adic numbers. Donald Knuth has an excellent book on the subject.
			I will try again in the future.
		</p>
			
		<p>
			<span className="tech">Technologies Used:</span>
			PHP.
		</p>
	</>);
}