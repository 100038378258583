import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Miva() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | PHP | MySQL | Linux | C/C++ | Miva | JavaScript | San Diego</title>
			<meta name="description" content={`I implemented a recurring billing system in PHP.
			Solved a long outstanding problem in Miva SQL engine written with C++. Implemented Verisign and PayPal payment modules for Miva.`} />
		</Helmet>

		<h1>Miva eCommerce Platform</h1>
		
		<p>
			Leading supplier of e-commerce software and services to small and medium-sized businesses.
			Here is the link for the company website: <a href="https://miva.com" target="_new">https://miva.com</a>
		</p>
		
		<h2>Accomplishments</h2>
		
		<ul>
			<li>
				<span className="feature">Billing System.</span>
				Implemented Modernbill recurring billing solution for company services and completed migration of old system.
				Started collecting unpaid services and saved over $50,000 per year.
			</li>
			<li>
				<span className="feature">SQL Server Update Index Bug.</span>
				Solved a long outstanding problem in SQL database engine B-tree indexes. Update and delete performances increased by as much as 100%.
			</li>
			<li>
				<span className="feature">Payment Gateways.</span>
				Integrated VeriSign and PayPal payment gateways with Miva. Enabled usage of MySQL 5, Oracle, Postgres, Sybase and Microsoft SQL Server as database backend.
				Expanded customer base and increased gateway revenue.
			</li>
		</ul>
		
		<p>
			<span className="tech">Technologies Used:</span>
			C/C++, PHP, Miva Script, JavaScript, SOAP, Web Services, MySQL, Postgres, Oracle, Linux, FreeBSD, Solaris and Windows.
		</p>
	</>);
}