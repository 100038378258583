import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function Brickfish() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | OLAP | Data Warehouse | Business Intelligence | Analytics | MDX | San Diego</title>
			<meta name="description" content={`Leading social media marketing company providing services for clients such as Coach, Kodak and Nike.
			Built OLAP data warehouse and business intelligence/reporting system with MDX.`} />
		</Helmet>
		
		<h1>Brickfish</h1>
		
		<p>
			Leading social media marketing company providing services for clients such as Coach, Kodak and Nike.
			Here is the link for the company website: <a href="https://brickfish.com" target="_new">https://brickfish.com</a>
		</p>
		
		<h2>Accomplishments</h2>
		
		<ul>
			<li>
				<span className="feature">OLAP data warehouse.</span>
				Built OLAP data warehouse and business analytics dashboard for reporting key performance indicators.
				Results: improved performance of main client reports from 15 minutes to 20 seconds.
			</li>
			<li>
				<span className="feature">E-commerce store.</span>
				There were a few stored procedures that were taking a long time. Optimized SQL queries. That improved the database server CPU usage by 24%.
			</li>
		</ul>
	
		<p>
			<span className="tech">Technologies Used:</span>
			MS SQL Server, Analysis Server, OLAP, MDX, ASP.NET, C#, JavaScript, AJAX.
		</p>
	</>);
}