import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows accomplishment
 */
export default function OrderPigeon() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | PHP | JavaScript | MySQL | Linux | Magento | San Diego</title>
			<meta name="description" content={`I created a system for updating inventory levels and fulfilling orders automatically on Amazon, eBay and similar channels.
			It used MySQL as database. I also created a cloud based ETL system to process product data to be loaded to Magento sites.`} />
		</Helmet>

		<h1>OrderPigeon</h1>
		
		<p>
			SaaS start-up with ~10 employees. Worked on a few projects but the main one was OrderPigeon.
			Here is the link for it: <a href="https://app.orderpigeon.com" target="_new">https://app.orderpigeon.com</a>
		</p>
		
		<h2>Accomplishments</h2>
	
		<ul>
			<li>
				<span className="feature">Designed and created OrderPigeon.</span>
				This is a service that updates inventory levels, ships orders and carries shipments between suppliers and e-commerce sites such as Amazon, eBay and Magento.
				It is for merchants with some drop-ship volume and high number of products (usually 100K+).
				Integrated with 50+ vendors and 10 order channels. Results: 750,000+ orders shipped with close to $100 million revenue.
				It is still live today. Acquired by Channel Advisor.
			</li>
			<li>
				<span className="feature">Designed and created CatalogSync.</span>
				This was a product content management system for extracting, transforming and loading (ETL) product data between vendors and e-commerce sites.
				Executed data projects for a number of customers. Results: 300K products/hour load speed on Magento. Data projects bringing over $100,000.
			</li>
		</ul>
	
		<p>
			<span className="tech">Technologies Used:</span>
			PHP, JavaScript, MySQL, ASP, MS SQL Server, SSIS, AWS, Magento.
		</p>
	</>);
}