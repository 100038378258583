import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * shows abacus info
 */
export default function Abacus() {
	
	return (<>
		<Helmet>
			<title>Software Engineer | Angular | Node | Netsuite | Salesforce | San Diego</title>
			<meta name="description" content={`TaaS (Technology as a Service) rapid-growth company in legal/accounting space.
			Designed and created client portal single page application for customers to manage tickets & services, update payment methods, pay outstanding invoices and
			purchase additional products and services. Integrated Netsuite and Salesforce. Scalable load balanced web servers.`} />
		</Helmet>
		
		<h1>Abacus Portal</h1>
		
		<p>
			TaaS (Technology as a Service) rapid-growth company in legal/accounting space.
			Designed and created client portal single page application for customers to manage tickets & services, update payment methods, pay outstanding invoices and
			purchase additional products and services. Integrated Netsuite and Salesforce. Scalable load balanced web servers.
			Here is the link for it: <a href="https://portal.abacusnext.com" target="_new">https://portal.abacusnext.com</a>
		</p>
		
		<h2>Features</h2>
	
		<ul>
			<li>
				<span className="feature">Customers can submit and manage tickets.</span>
				The tickets get created automatically in Netsuite. Customers can view the messages and status of their tickets.
				Workflow specific tickets were enabled as well. Example: customers can enter their cloud users and submit a ticket so that support can create them.
				Results: 100K+ users, 11K+ tickets.
			</li>
			<li>
				<span className="feature">Customers can update their info and contacts.</span>
				This saved the company time. Customers had to call before to change their phone number, add contacts, etc.
			</li>
			<li>
				<span className="feature">Customers can update card/account on file and pay invoices.</span>
				This saved the company time. Customers had to call before to change their card number. Collections had to be done manually by Finance.
			</li>
			<li>
				<span className="feature">E-commerce store</span> that allows sales people to enter quotes and customers to check out by themselves using those quotes. Heavy approval process for quotes and discounts.
				It is architected such that customers can also purchase products and services by themselves.
			</li>
			<li>
				<span className="feature">Automatic payment collection.</span>
				This is a challenge due to Netsuite. Invoices get synced real time to MySQL and paid and updated back.
				Used company payment gateway (APX) and saved over $250K per year. Outstanding credits get automatically used.
			</li>
			<li>
				<span className="feature">External and internal ticket management system.</span>
				Mostly completed. 3 teams were migrated to use the new system. Saved money by not having to create those users in Netsuite.
			</li>
			<li>
				<span className="feature">Licensing system.</span>
				Company had a bunch of desktop applications that required a central licensing system. Designed and built a unified licensing system that
				can use static fields (for customers that are authorized to use the system without paying for it) as well as subscriptions (for customers that need to pay).
				Handled automatic fulfillment of licenses when orders are entered.
			</li>
			<li>
				<span className="feature">Cloud Automation.</span>
				Designed a new Azure-like automation system to manage cloud users and VMs. Integrated with SCVMM and SCCM. Secure operations controlled by Azure MFA.
			</li>
		</ul>
	
		<p>
			<span className="tech">Technologies Used:</span>
			JavaScript (ES5 and ES6+), Angular 2+, D3, Material, NodeJS, Koa, Express, TypeScript, MySQL, C#, PHP, Jenkins, Netsuite, Git, HTML, CSS, SCSS, REST, Azure, Linux.
		</p>
	</>);
}